import React from "react";
import bgImg from "../assets/vp_pic.png";

export const Problem = () => {
  return (
    <section name="problem" className="w-full h-screen bg-gray-900 flex flex-col justify-center">
      <div className="w-full h-full bg-gray-900/90 absolute mix-blend-overlay">
        <img className="w-full h-full object-cover" src={bgImg} alt="background img" />
      </div>

      <div className="max-w-[1240px] mx-auto text-white relative">
        <div className="px-4 py-8 md:py-12 text-center">

          <div className="my-4 py-4">
            <h2 className="text-4xl md:text-5xl pt-2 md:pt-8 uppercase">
              The Problem
            </h2>
          </div>

          <p className="text-3xl md:text-4xl">
            Alternative and qualitative investment research is difficult, time intensive, and cannot be replaced by an AI chat prompt.
            Millions cannot be invested without proper research, citing, and writing.
            We understand that without a full vertical, you are not truly solving the problem, only part of it.
            Competitors do not have a full vertical and are missing key parts.
          </p>

        </div>
      </div>
    </section>
  );
}