import React, { useState } from "react";
import { Link } from "react-scroll";

import { MenuIcon, XIcon } from "@heroicons/react/outline";
import LogoImg from "../assets/valiant_logo.png";


export const Navbar = () => {
  const [nav, setNav] = useState(false)
  
  const handleClick = () => setNav(!nav)
  const handleClose = () => setNav(!nav)

  return (
    <nav className="w-screen h-[80px] z-10 bg-zinc-200 fixed top-0 drop-shadow-lg">
      <div className="px-2 flex justify-between items-center w-full h-full bg-gray-900">
        <div className="flex items-center w-full">
          <div className="mx-4 h-12 w-12">
            <img src={LogoImg} alt="logo"/>
          </div>
          <ul className="hidden md:flex text-white mx-auto">
            <li>
              <Link className="hover:cursor-pointer" to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link className="hover:cursor-pointer" to="about" smooth={true} offset={-50} duration={500}>
                About
              </Link>
            </li>
            <li>
              <Link className="hover:cursor-pointer" to="problem" smooth={true} offset={-50} duration={500}>
                The Problem
              </Link>
            </li>
            <li>
              <Link className="hover:cursor-pointer" to="features" smooth={true} offset={0} duration={500}>
                Features
              </Link>
            </li>
            <li>
              <Link className="hover:cursor-pointer" to="team" smooth={true} offset={-50} duration={500}>
                Our Team
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:hidden mx-4 text-white" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-8" /> : <XIcon className="w-8" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute  w-full px-8 text-white bg-gray-800"}>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link className="hover:cursor-pointer" onClick={handleClose} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link className="hover:cursor-pointer" onClick={handleClose} to="about" smooth={true} offset={-50} duration={500}>
            About
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link className="hover:cursor-pointer" onClick={handleClose} to="problem" smooth={true} offset={0} duration={500}>
            The Problem
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link className="hover:cursor-pointer" onClick={handleClose} to="features" smooth={true} offset={-50} duration={500}>
            Features
          </Link>
        </li>
        <li className="border-b-2 border-zinc-300 w-full">
          <Link className="hover:cursor-pointer" onClick={handleClose} to="team" smooth={true} offset={-50} duration={500}>
            Our Team
          </Link>
        </li>
      </ul>
    </nav>
  );
}
