import {
  About,
  Features,
  Footer,
  Hero,
  Navbar,
  Problem,
  Team,
} from "./components";

const App = () => (
  <>
    <Navbar />
    <main>
      <Hero />
      <About />
      <Problem />
      <Features />
      <Team />
    </main>
    <Footer />
  </>
);

export default App;
