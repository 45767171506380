import React from "react";

import {
  DavidImg,
  DonnyImg,
  ClarenceImg,
  TroyImg,
} from "../assets/team";

const teamArray = [
  {
    name: "David Serabian",
    title: "Chief Executive Officer",
    link: "https://www.linkedin.com/in/david-serabian",
    img: DavidImg,
    blurb: "David has been a research consultant for a Fortune 500 CEO family office, and a US Presidential Cybersecurity Commission. With the commission his policy recommendations were included to the President. He previously founded a fintech startup as a CCO. David has a passion for developing organizations and being a servant leader.",
  },
  {
    name: "Donny Vallejo",
    title: "Chief Operating Officer",
    link: "https://donnyvallejo.netlify.app",
    img: DonnyImg,
    blurb: "Donny is our COO. He is a skilled software developer Who has experience scaling a software startup. He Taught classes at UC Berkeley and Is incredible at building teams.",
  },
  {
    name: "Clarence Cheung",
    title: "Software Engineer",
    link: "https://clarencec-site.netlify.app",
    img: ClarenceImg,
    blurb: "Clarence is a Software engineer and he has a pragmatic approach to problem solving, incredible drive, and tireless work ethic.",
  },
  {
    name: "Troy Grossi",
    title: "Software Engineer",
    link: "https://www.linkedin.com/in/troy-grossi/",
    img: TroyImg,
    blurb: "Troy is a full-stack software engineer who focus on building scalable and maintainable applications. He has a degree in CS and attended classes at UC berkeley. He was an Eagle Scout, a former varsity wrestler, and a rugby player.",
  },
]

const TeamCard = ({ name="Name", title="Engineer", link="#", blurb="say something nice", img="" }) => {
  return (
    <div className="flex flex-col lg:flex-row m-4 px-4 md:px-8 bg-gray-300 text-black">
      <div className="flex flex-col justify-center items-center">
        <div className="w-[200px] py-4">
          <img 
            className="w-full rounded-full bg-gray-400" 
            src={img} 
            alt={`team member`}
          />
        </div>
        <div className="flex justify-center text-center">
          <div className="p-2">
            <h3 className="font-bold text-lg">
              {name}
            </h3>
            <p className="text-lg pt-2 pb-4">
              {title}
            </p>
          </div>
        </div>
      </div>
      <div className="mx-4 py-4 lg:pt-8 flex flex-col">
        <p className="p-2">
          {blurb}
        </p>
        <div className="px-2 py-4">
          <a 
            href={link} 
            target="_blank" 
            rel="rel noopener noreferrer"
            className="rounded-md py-2 px-4 text-black hover:bg-gray-800 hover:text-white border-gray-800 border-2"
          >
            Learn More
          </a>
        </div>
      
      </div>
    </div>
  );
}

export const Team = () => {
  return (
    <section name="team" className="w-full mx-auto px-2 pb-8 bg-gray-900">
      <h2 className="text-4xl font-bold text-center text-white py-16">
        Our Team
      </h2>
      <div className="grid md:grid-cols-2 gap-4 pt-4 text-white">
        {teamArray.map((member, index) => (
          <TeamCard 
            key={`tm_${index}`}
            name={member.name}
            title={member.title}
            link={member.link}
            blurb={member.blurb}
            img={member.img}
          />
        ))}
      </div>
    </section>
  );
}