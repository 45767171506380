import React from "react";

const featuresArr = [
  {
    key: "text_1",
    title: "1) Automated Data Aggregation",
    content: "Scrapes from open sources, consolidates information from trusted sources, supports multiple data types, and a Chrome extension for easy source collection and citation."
  },
  {
    key: "text_2",
    title: "2) AI-Driven Analysis ",
    content: "Provides intelligent summarization with page-specific citations, real-time financial and regulatory updates, trend identification, and risk analysis."
  },
  {
    key: "text_3",
    title: "3) Knowledge Management",
    content: "Features a centralized Wiki-style repository, team collaboration tools, source tracking and citation management, and a historical project archive with automatic updates."
  },
  {
    key: "text_4",
    title: "4) Report Generation",
    content: "Enables automated report and memo creation, professional email drafting, custom charts and graphs, and template management."
  },
  {
    key: "text_5",
    title: "5) Workflow Customization",
    content: "Offers project-specific organization, client-centric views, and automated update scheduling."
  },
]

export const Features = () => {
  return (
    <section name="features" className="w-full py-24 bg-gray-800 min-h-screen px-4">
      <div className="m-4 p-4 text-center">
        <h2 className="text-white text-5xl">
          Key Features
        </h2>
      </div>
      {featuresArr.map(item => (
        <div className="text-white my-4 px-4" key={item.key}>
          <h3 className="font-bold text-2xl">
            {item.title}
          </h3>
          <p className="text-xl pt-2 pb-4">
            {item.content}
          </p>
        </div>
      ))}
    </section>
  );
}

